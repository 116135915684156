.tools {
  margin-top: 40px;
  margin-bottom: 50px; }
.tools__title {
  margin-bottom: 50px; }

.tool {
  & + .tool {
    margin-top: 100px; } }

.tool__content {}

.tool__title {
  font-size: 21px;
  font-weight: bold;
  margin-bottom: 32px; }

.tool__text {
  font-family: "Freeset";
  margin-bottom: 40px; }

.tool__slider {
  width: 430px;
  float: right;
  max-width: 100%;
  &.left {
    float: left;
    @media (max-width: $screen-md-min) {
      float: none;
      margin: 0 auto 30px; } }
  @media (max-width: $screen-md-min) {
    float: none;
    margin: 30px auto 0; } }

.tool_carousel_1 {
  margin-bottom: 12px;
  .item {
    width: 100%;
    height: 320px;
    border: 1px solid #e3e3e3;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat; } }

.tool_carousel_1_thumbs {
  .item {
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    height: 100px;
    border: 1px solid #e3e3e3; }
  .current {
    .item {
      position: relative;
      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,0.3); } } } }
