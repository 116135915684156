.help {
  padding: 66px 33px 30px;
  position: relative;
  @media(max-width: $screen-md-min) {
    padding-left: 0;
    padding-right: 0;
    position: static; } }

.help__wrapper {
  overflow: hidden;
  position: relative; }

.help__text {
  font-family: "Freeset";
  margin-bottom: 20px;
  max-width: 464px; }

.help__brands {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 444px;
  flex-wrap: wrap;
  margin-top: 60px; }

.help__qote_wrapper {
  width: 100%;
  padding: 0 117px;
  margin-top: 163px;
  margin-bottom: 64px;
  @media(max-width: $screen-md-min) {
    padding: 0; } }

.help__manager {
  position: absolute;
  right: -30px;
  top: 31px;
  z-index: -1;
  img {
    width: 100%; }
  @media(max-width: $screen-md-min) {
    right: -115px;
    top: 10px;
    width: 333px; } }

.help__manager__name {
  text-align: right;
  margin-top: -66px;
  margin-right: 35%;
  @media(max-width: $screen-md-min) {
    display: none; } }
