.thumb {
  width: 100%;
  margin-bottom: 80px;
  position: relative;
  &.grey {
    .thumb__head, .thumb__circle {
      background: #9c9c9c; } }
  &.blue {
    .thumb__head, .thumb__circle {
      background: $brand-primary; } }
  &.red {
    .thumb__head, .thumb__circle {
      background: #e74646; } } }

.thumb__head {
  color: #fff;
  font-size: 30px;
  line-height: 35px;
  padding: 15px 40px;
  text-transform: uppercase;
  text-align: center;
  border-radius: 40px 40px 0 0;
  font-weight: bold;
  letter-spacing: 1px;
  &.large {
    padding: 40px 40px; }
  @media (max-width: $screen-lg-min) {
    padding: 15px 20px;
    font-size: 25px;
    line-height: 29px; } }

.thumb__body {
  color: #808080;
  padding: 30px 15px 60px;
  &:not(.simple) {
    color: #fff;
    padding: 30px 40px 60px; }
  font-size: 21px;
  overflow: hidden;
  border-radius: 0 0 40px 40px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  font-weight: 300;
  position: relative;
  background-color: #f5f3f4;
  &:not(.simple):before {
    content: '';
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: #000;
    opacity: 0.4; }
  & > * {
    z-index: 2;
    position: relative; }
  b {
    font-weight: bold;
    text-transform: uppercase; }
  @media (max-width: $screen-lg-min) {
    padding: 30px 20px 50px; } }

.thumb__body__text {
  max-width: 70%;
  margin: 0 auto;
  @media (max-width: $screen-lg-min) {
    max-width: 100%; } }

.thumb__body {
  &.simple {
    .thumb__body__text {
      max-width: 100%;
      text-align: left; } } }

.thumb__circle {
  border-radius: 100%;
  color: #fff;
  text-align: center;
  font-weight: bold;
  text-transform: uppercase;
  width: 96px;
  height: 96px;
  position: absolute;
  bottom: -48px;
  left: 50%;
  margin-left: -48px;
  z-index: 4;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 31px;
  letter-spacing: 1px;
  font-family: "Freeset";
  &:after {
    content: '';
    position: absolute;
    left: -14px;
    top: -14px;
    width: 124px;
    height: 124px;
    background-repeat: no-repeat;
    background-size: contain; }
  @media (max-width: $screen-lg-min) {
    width: 70px;
    height: 70px;
    margin-left: -35px;
    bottom: -35px;
    font-size: 18px;
    &:after {
      content: '';
      position: absolute;
      left: -12px;
      top: -12px;
      width: 92px;
      height: 92px;
      background-repeat: no-repeat;
      background-size: contain; } }
  & > svg {
    position: absolute;
    left: -17px;
    top: -17px; } }

.thumb {
  &.grey {
    .thumb__circle:after {
      background-image: url('/assets/img/icons/circle-grey.png'); } }
  &.blue {
    .thumb__circle:after {
      background-image: url('/assets/img/icons/circle-blue.png'); } }
  &.red {
    .thumb__circle:after {
      background-image: url('/assets/img/icons/circle-red.png'); } } }
