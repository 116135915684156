.selection {
  background: url('/assets/img/selection/background.jpg');
  padding: 70px 0;
  text-align: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center; }

.selection__title {
  display: block;
  padding: 0 217px;
  margin-bottom: 26px;
  @media(max-width: $screen-md-min) {
    padding: 0; } }

.selection__text {
  color: #fff;
  text-align: center;
  display: block;
  padding: 0 217px;
  font-weight: 100;
  letter-spacing: 1px;
  line-height: 24px;
  margin-bottom: 36px;
  @media(max-width: $screen-md-min) {
    padding: 0; } }
.selecton__qote_wrapper {
  width: 100%;
  padding: 0 150px;
  margin-top: 84px;
  @media(max-width: $screen-md-min) {
    padding: 0; } }
