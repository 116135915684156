form.filter {
  border: 1px solid #e3e3e3;
  padding: 20px 0;
  margin-bottom: 30px;

  & fieldset {
    padding: 0 20px;
    & + fieldset {
      margin-top: 20px;
      padding-top: 20px;
      border-top: 1px solid #e3e3e3; } } }

.filter_collapse_btn {
  margin-bottom: 30px;
  border-radius: 0;
  width: 100%;
  & .caret {
    background-image: url('/assets/img/icons/arrow-white.png');
    margin-left: 6px;
    transform: rotate(180deg);
    transition: all .2s ease; }
  &.collapsed {
    & .caret {
      transform: rotate(00deg);
      transition: all .2s ease; } }

  @media(min-width: $screen-md-min) {
    display: none; }
  @media(max-width: $screen-sm-min) {
    width: calc(100% + 30px);
    margin-left: -15px; } }

.filter-collapse {
  padding: 0; }

.filter__title {
  color: $brand-primary;
  font-weight: normal;
  font-size: 16px;
  text-transform: uppercase; }

.filter__checkbox {
  margin-bottom: 0;
  display: block;
  clear: both;
  & + .filter__checkbox {
    margin-top: 5px; } }

.filter__checkbox-color__check {
  position: absolute;
  left: 50%;
  margin-left: -7px;
  top: 50%;
  margin-top: -5px;
  opacity: 0;
  transition: all 0.2s ease; }

.filter__checkbox-color {
  width: 40px;
  height: 40px;
  position: relative;
  border-radius: 100%;
  margin-right: 5px;
  padding: 4px;
  clear: unset;
  float: left;
  margin-top: 0;
  cursor: pointer;
  margin-bottom: 5px;
  & input {
    position: absolute;
    left: -9999px; }
  & + .filter__checkbox-color {
    margin-top: 0; }
  & > input:checked {
    + .filter__checkbox-color__check {
      opacity: 1;
      transition: all 0.2s ease; } } }

.filter__checkbox__collapse {
  margin-top: 5px;
  & .filter__checkbox {
    &:last-child {
 } } }      // margin-bottom: 10px

.filter__checkbox__collapse__link {
  margin-top: 10px;
  display: block;
  font-family: "Freeset";
  float: left;
  font-size: 12px;
  border-bottom: 1px dashed $brand-primary;
  color: $brand-primary;
  text-decoration: none;
  line-height: 12px;
  &:hover, &:focus {
    text-decoration: none; } }

.filter__title__count {
  color: #7c7b7c; }

.filter__actions {
  padding: 20px 20px 0;
  margin-top: 30px; }

.filter__reset {
  text-transform: uppercase;
  width: 100%;
  padding-left: 0;
  padding-right: 0;
  text-align: center; }

.filter__link {
  display: block;
  & + .filter__link {
    margin-top: 7px; } }
