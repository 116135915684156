.collection {}

.collection__card {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 15px;
  border: 1px solid #e3e3e3;
  margin-bottom: 30px;
  @media(max-width: $screen-md-max) {
    flex-direction: column; }
  @media(max-width: $screen-sm-min) {
    border: none;
    padding: 0; }
  &.collection__card-detailed {
    flex-wrap: wrap;
    & .collection__card__content {
      padding-left: 15px;
      min-width: 50%;
      width: 50%;
      @media(max-width: $screen-md-max) {
        width: 100%;
        padding-left: 0; } } } }

.collection__card__gallery {
  width: 50%;
  min-width: 50%;
  @media(max-width: $screen-md-max) {
    width: 100%; } }

.collection__card__title {
  margin-top: 0;
  @media(max-width: $screen-md-min) {
    margin-top: 20px; } }

.collection__card__info {
  width: 200px;
  min-width: 200px;
  text-align: center;
  margin-right: 40px;
  @media(max-width: $screen-md-max) {
    text-align: left; } }

.collection__card__logo {
  max-width: 70%;
  margin-bottom: 30px;
  @media(max-width: $screen-md-max) {
    margin-bottom: 0; } }

.collection__card-detailed {
  .collection__card__logo {
    height: 65px;
    margin-bottom: 12px; } }

.collection__card__table {
  width: 100%;
  & > tbody {
    & > tr {
      &:nth-child(2n+1) {
        background: #f9f7fa; }
      & td {
        font-size: 14px;
        font-family: "Freeset";
        padding: 6px 12px 5px;
        line-height: 17px;
        &:nth-child(2) {
          font-weight: bold; } } } } }

.collection__card__links {
  text-align: left;
  &.horizontal {
    background: #f9f7fa;
    width: calc(100% + 30px);
    min-width: calc(100% + 30px);
    margin-left: -15px;
    margin-right: -15px;
    margin-top: 20px;
    padding: 4px 15px 10px;
    margin-bottom: -15px;
    border-top: 1px solid #e3e3e3;
    .collection__card__link {
      display: inline-block;
      & + .collection__card__link {
        margin-left: 30px;
        padding-left: 24px; } } } }
.collection__card__link {
  font-size: 14px;
  display: block;
  position: relative;
  padding-left: 34px;
  &:before {
    content: '';
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    left: 0;
    // top: 50%
 }    // margin-top: -10px
  &.presentation {
    &:before {
      background-image: url('/assets/img/icons/pdf.png');
      width: 17px;
      height: 17px;
      top: 50%;
      margin-top: -11px; } }
  &.certificate {
    &:before {
      background-image: url('/assets/img/icons/sertificat.png');
      width: 16px;
      height: 14px;
      top: 50%;
      margin-top: -7px; } }
  &.conditions {
    &:before {
      background-image: url('/assets/img/icons/check-bordered.png');
      width: 14px;
      height: 13px;
      top: 50%;
      margin-top: -6px; } }

  & + .collection__card__link {
    margin-top: 10px; } }

.collection__card__content {}

.collection__card__text {
  font-family: "Freeset"; }

.collection__sort {
  display: flex;
  align-items: center;
  justify-content: space-between;
  & .mse2_sort {
    flex: 1; } }
