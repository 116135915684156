.footer {
  position: relative;
  z-index: 0;
  width: 100%;
  padding: 44px 0;
  margin-top: 0px;
  background: #000000;
  color: #fff;
  a {
    color: #fff; } }

.footer__blocks {
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  flex-wrap: wrap;
  @media (max-width: $screen-md-min) {
    justify-content: space-between;
    & > div {
      width: 50%; } }
  @media (max-width: $screen-sm-min) {
    justify-content: flex-start;
    & > div {
      width: 100%;
      & + div {
        margin-top: 60px; } } } }

.footer__policy {
  font-size: 14px;
  font-family: "Freeset";
  min-width: 265px;
  width: 265px;
  margin-right: 60px;
  & a {
    position: relative;
    &:after {
      content: '';
      display: table;
      clear: both; }
    &:before {
      content: '';
      position: absolute;
      left: 0;
      width: 100%;
      height: 1px;
      bottom: 0;
      background: #404040; }
    &:hover {
      text-decoration: none; } }
  @media (max-width: $screen-lg-min) {
    margin-right: 30px; }
  @media (max-width: $screen-md-min) {
    margin-bottom: 30px;
    margin-right: 0;
    padding-right: 50px; } }

.footer__policy__row {
  display: block;
  & + .footer__policy__row {
    margin-top: 20px; } }

.footer__menu {
  width: 246px;
  @media (max-width: $screen-lg-min) {
    width: 215px; }
  & + .footer__menu {
    @media (max-width: $screen-md-min) {
      margin-top: 30px; }
    @media (max-width: $screen-sm-min) {
      margin-top: 60px; } } }

.footer__menu__link {
  display: block;
  font-size: 14px;
  &:first-child {
    font-size: 21px;
    margin-bottom: 15px; }
  & + .footer__menu__link {
    margin-top: 5px; } }

.footer__contacts {
  text-align: right;
  @media (max-width: $screen-md-min) {
    text-align: left;
    margin-top: 30px; } }

.footer__contacts__title {
  font-size: 21px;
  margin-bottom: 15px;
  display: block; }

.footer__contacts__contact {
  & > span {
    color: #7c7b7c;
    font-size: 12px;
    font-family: "Freeset";
    font-weight: 300;
    display: block;
    margin-bottom: -2px; }
  & > a {
    font-size: 18px; }
  & + .footer__contacts__contact {
    margin-top: 12px; } }

.footer__social {
  margin-top: 15px; }
.footer__social__link {
  text-decoration: none;
  opacity: 0.5;
  &:hover {
    text-decoration: none;
    opacity: 1; }
  & + .footer__social__link {
    margin-left: 18px; } }
