.products_table {
  table {
    border: 2px solid #dad8db;
    thead {
      & > tr {
        & > th {
          font-size: 18px;
          text-transform: uppercase;
          text-align: center;
          color: $brand-primary;
          vertical-align: middle;
          border-bottom: 1px solid #dad8db;
          position: relative;
          padding: 0;
          padding-top: 0;
          padding-bottom: 0;
          & .text-danger {
            white-space: nowrap; }
          & + th {
            border-left: 1px solid #dad8db; }
          & > div {
            width: 100%;
            height: 100%;
            padding: 15px 30px; }
          &.popular {
            border-bottom: 1px solid transparent;
            & > div {
              &::before {
                content: '';
                width: calc(100% + 8px);
                height: calc(100% + 5px);
                left: -4px;
                top: -4px;
                position: absolute;
                background: none;
                border: 9px solid $brand-primary;
                border-bottom: 0; } } } } } } }

  tbody {
    tr {
      td {
        padding: 0;
        border-top: 1px solid transparent;
        color: $brand-primary;
        vertical-align: middle;
        text-align: center;
        position: relative;
        padding-top: 0!important;
        font-weight: bold;
        font-size: 18px;
        padding-bottom: 0!important;
        & > div {
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
          padding: 10px 18px; }
        &:first-child {
          text-align: left;
          font-weight: 400;
          &.sub-td {
            color: #000;
            font-size: 14px;
            font-family: "Freeset";
            & > div {
              padding-left: 36px; } } }
        & + td {
          border-left: 1px solid #dad8db; }
        &.popular {
          & > div {
            &::before {
              content: '';
              width: 9px;
              position: absolute;
              height: calc(100% + 1px);
              background: $brand-primary;
              left: -4px;
              top: 0;
              z-index: 2; }
            &::after {
              content: '';
              width: 9px;
              position: absolute;
              height: calc(100% + 1px);
              background: $brand-primary;
              right: -4px;
              top: 0;
              z-index: 2; } } } }
      &:last-child {
        td {
          &.popular {
            & > div {
              &::after {
                content: '';
                width: calc(100% + 8px);
                height: calc(100% + 4px);
                left: -4px;
                bottom: -4px;
                position: absolute;
                background: none;
                border: 9px solid $brand-primary;
                border-top: 0; }
              &::before {
                display: none; } } } } }
      &:hover {
        td {
          border-top: 1px solid #dad8db;
          border-bottom: 1px solid #dad8db;
          background: #fcfcfc; } } } } }
