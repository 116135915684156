/*!
 * Fotorama 4.6.4 | http://fotorama.io/license/
 */
.fotorama__nav__frame:focus .fotorama__dot:after, .fotorama__nav__frame:focus .fotorama__thumb:after, .fotorama__fullscreen-icon:focus:after,
.fotorama__arr:focus:after, .fotorama__stage__shaft, .fotorama__stage__frame, .fotorama__img, .fotorama__html, .fotorama__video iframe {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0; }

.fotorama--fullscreen, .fotorama__img {
  max-width: 99999px !important;
  max-height: 99999px !important;
  min-width: 0 !important;
  min-height: 0 !important;
  border-radius: 0 !important;
  box-shadow: none !important;
  padding: 0 !important; }

.fotorama__wrap .fotorama__grab {
  cursor: move;
  cursor: -webkit-grab;
  cursor: -o-grab;
  cursor: -ms-grab;
  cursor: grab; }

.fotorama__grabbing * {
  cursor: move;
  cursor: -webkit-grabbing;
  cursor: -o-grabbing;
  cursor: -ms-grabbing;
  cursor: grabbing; }

.fotorama__spinner {
  position: absolute !important;
  top: 50% !important;
  left: 50% !important; }

.fotorama__wrap--css3 .fotorama__stage__shaft,
.fotorama__wrap--css3 .fotorama__nav__shaft,
.fotorama__wrap--css3 .fotorama__thumb-border, .fotorama__wrap--css3 .fotorama__arr, .fotorama__wrap--css3
.fotorama__fullscreen-icon, .fotorama__wrap--css3
.fotorama__video-play, .fotorama__wrap--css3
.fotorama__video-close {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.fotorama__caption, .fotorama__wrap--css3 .fotorama__spinner, .fotorama__wrap--css3 .fotorama__stage,
.fotorama__wrap--css3 .fotorama__nav,
.fotorama__wrap--css3 .fotorama__stage__frame, .fotorama__wrap--css3 .fotorama__stage .fotorama__img,
.fotorama__wrap--css3 .fotorama__html, .fotorama__stage:before, .fotorama__stage:after, .fotorama__nav:before, .fotorama__nav:after {
  -webkit-transform: translateZ(0);
  transform: translateZ(0); }

.fotorama__nav__frame, .fotorama__fullscreen-icon:focus,
.fotorama__arr:focus {
  outline: 0; }

.fotorama__nav__frame:focus .fotorama__dot:after, .fotorama__nav__frame:focus .fotorama__thumb:after, .fotorama__fullscreen-icon:focus:after,
.fotorama__arr:focus:after {
  content: '';
  border-radius: inherit;
  background-color: rgba(0, 175, 234, 0.5); }

.fotorama__wrap--video .fotorama__stage__shaft,
.fotorama__wrap--video .fotorama__stage,
.fotorama__wrap--video .fotorama__stage__frame--video, .fotorama__wrap--video .fotorama__stage__frame--video .fotorama__img,
.fotorama__wrap--video .fotorama__stage__frame--video .fotorama__html {
  -webkit-transform: none !important;
  transform: none !important; }

.fotorama__wrap--css3 .fotorama__stage__shaft,
.fotorama__wrap--css3 .fotorama__nav__shaft,
.fotorama__wrap--css3 .fotorama__thumb-border {
  transition-property: -webkit-transform, width;
  transition-property: transform, width;
  transition-timing-function: cubic-bezier(0.1, 0, 0.25, 1);
  transition-duration: 0ms; }

.fotorama__wrap, .fotorama__no-select, .fotorama__arr,
.fotorama__fullscreen-icon,
.fotorama__video-play,
.fotorama__video-close {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.fotorama__select {
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text; }

.fotorama__nav, .fotorama__nav__frame {
  margin: auto;
  padding: 0; }

.fotorama__nav__shaft, .fotorama__nav__frame, .fotorama__caption__wrap {
  -moz-box-orient: vertical;
  display: inline-block;
  vertical-align: middle;
  *display: inline;
  *zoom: 1; }

.fotorama__nav__frame, .fotorama__thumb-border {
  box-sizing: content-box; }

.fotorama__caption__wrap {
  box-sizing: border-box; }

.fotorama--hidden, .fotorama__load {
  position: absolute;
  left: -99999px;
  top: -99999px;
  z-index: -1; }

.fotorama__stage__shaft, .fotorama__stage__frame, .fotorama__nav, .fotorama__nav__shaft, .fotorama__nav__frame, .fotorama__arr,
.fotorama__fullscreen-icon,
.fotorama__video-play,
.fotorama__video-close {
  -webkit-tap-highlight-color: transparent; }

.fotorama__arr, .fotorama__fullscreen-icon, .fotorama__video-play, .fotorama__video-close {
  background: url("/assets/img/icons/fotorama.png") no-repeat; }
  @media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 2dppx) {
    .fotorama__arr, .fotorama__fullscreen-icon, .fotorama__video-play, .fotorama__video-close {
      background: url("/assets/img/icons/fotorama@2x.png") no-repeat;
      background-size: 96px 160px; } }

.fotorama__thumb {
  background-color: #7f7f7f;
  background-color: rgba(127, 127, 127, 0.2); }

@media print {
  .fotorama__arr, .fotorama__fullscreen-icon, .fotorama__video-play, .fotorama__video-close, .fotorama__thumb-border {
    background: none !important; } }

.fotorama {
  min-width: 1px;
  overflow: hidden; }
  .fotorama:not(.fotorama--unobtrusive) > *:not(:first-child) {
    display: none; }

.fullscreen {
  width: 100% !important;
  height: 100% !important;
  max-width: 100% !important;
  max-height: 100% !important;
  margin: 0 !important;
  padding: 0 !important;
  overflow: hidden !important;
  background: #000; }

.fotorama--fullscreen {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  float: none !important;
  z-index: 2147483647 !important;
  background: #000;
  width: 100% !important;
  height: 100% !important;
  margin: 0 !important; }
  .fotorama--fullscreen .fotorama__stage,
  .fotorama--fullscreen .fotorama__nav {
    background: #000; }

.fotorama__wrap {
  -webkit-text-size-adjust: 100%;
  position: relative;
  direction: ltr;
  z-index: 0; }

.fotorama__wrap--rtl .fotorama__stage__frame {
  direction: rtl; }

.fotorama__stage,
.fotorama__nav {
  overflow: hidden;
  position: relative;
  max-width: 100%; }

.fotorama__wrap--pan-y {
  -ms-touch-action: pan-y; }

.fotorama__wrap .fotorama__pointer {
  cursor: pointer; }

.fotorama__wrap--slide .fotorama__stage__frame {
  opacity: 1 !important; }

.fotorama__stage__frame {
  overflow: hidden; }
  .fotorama__stage__frame.fotorama__active {
    z-index: 8; }

.fotorama__wrap--fade .fotorama__stage__frame {
  display: none; }
.fotorama__wrap--fade .fotorama__stage__frame.fotorama__active,
.fotorama__wrap--fade .fotorama__fade-front,
.fotorama__wrap--fade .fotorama__fade-rear {
  display: block;
  left: 0;
  top: 0; }
.fotorama__wrap--fade .fotorama__fade-front {
  z-index: 8; }
.fotorama__wrap--fade .fotorama__fade-rear {
  z-index: 7; }
  .fotorama__wrap--fade .fotorama__fade-rear.fotorama__active {
    z-index: 9; }
.fotorama__wrap--fade .fotorama__stage .fotorama__shadow {
  display: none; }

.fotorama__img {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  opacity: 0;
  border: none !important; }
  .fotorama__loaded .fotorama__img, .fotorama__error .fotorama__img {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: alpha(opacity=100);
    opacity: 1; }

.fotorama__img--full {
  display: none; }

.fotorama--fullscreen .fotorama__loaded--full .fotorama__img {
  display: none; }
.fotorama--fullscreen .fotorama__loaded--full .fotorama__img--full {
  display: block; }

.fotorama__wrap--only-active .fotorama__stage,
.fotorama__wrap--only-active .fotorama__nav {
  max-width: 99999px !important; }
.fotorama__wrap--only-active .fotorama__stage__frame {
  visibility: hidden; }
.fotorama__wrap--only-active .fotorama__stage__frame.fotorama__active {
  visibility: visible; }

.fotorama__nav {
  font-size: 0;
  line-height: 0;
  text-align: center;
  display: none;
  white-space: nowrap;
  z-index: 5; }

.fotorama__nav__shaft {
  position: relative;
  left: 0;
  top: 0;
  text-align: left; }

.fotorama__nav__frame {
  position: relative;
  cursor: pointer; }

.fotorama__nav--dots {
  display: block; }
  .fotorama__nav--dots .fotorama__nav__frame {
    width: 18px;
    height: 30px; }
  .fotorama__nav--dots .fotorama__nav__frame--thumb,
  .fotorama__nav--dots .fotorama__thumb-border {
    display: none; }

.fotorama__nav--thumbs {
  display: block; }
  .fotorama__nav--thumbs .fotorama__nav__frame {
    padding-left: 0 !important; }
    .fotorama__nav--thumbs .fotorama__nav__frame:last-child {
      padding-right: 0 !important; }
  .fotorama__nav--thumbs .fotorama__nav__frame--dot {
    display: none; }

.fotorama__dot {
  display: block;
  width: 8px;
  height: 8px;
  position: relative;
  top: 12px;
  left: 6px;
  background: #7f7f7f;
  border-radius: 6px;
  border: 1px solid #7f7f7f; }
  .fotorama__nav__frame:focus .fotorama__dot:after {
    padding: 1px;
    top: -1px;
    left: -1px; }
  .fotorama__nav__frame.fotorama__active .fotorama__dot {
    width: 8px;
    height: 8px;
    border-width: 3px;
    background: #000;
    border-color: #000;
    border-width: 3px; }
    .fotorama__nav__frame.fotorama__active .fotorama__dot:after {
      padding: 3px;
      top: -3px;
      left: -3px; }

.fotorama__thumb {
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100%; }
  .fotorama__nav__frame:focus .fotorama__thumb {
    z-index: 2; }

.fotorama__thumb-border {
  position: absolute;
  z-index: 9;
  top: 0;
  left: 0;
  border-style: solid;
  border-color: #f11a3e;
  background-image: linear-gradient(to bottom right, rgba(255, 255, 255, 0.25), rgba(64, 64, 64, 0.1)); }

.fotorama__caption {
  position: absolute;
  z-index: 12;
  bottom: 0;
  left: 0;
  right: 0;
  font-family: 'Helvetica Neue', Arial, sans-serif;
  font-size: 14px;
  line-height: 1.5;
  color: #000; }
  .fotorama__caption a {
    text-decoration: none;
    color: #000;
    border-bottom: 1px solid;
    border-color: rgba(0, 0, 0, 0.5); }
    .fotorama__caption a:hover {
      color: #333;
      border-color: rgba(51, 51, 51, 0.5); }
  .fotorama__wrap--rtl .fotorama__caption {
    left: auto;
    right: 0; }
  .fotorama__wrap--video .fotorama__caption, .fotorama__wrap--no-captions .fotorama__caption {
    display: none; }

.fotorama__caption__wrap {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 5px 10px; }

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0); }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@keyframes spinner {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0); }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }
.fotorama__wrap--css3 .fotorama__spinner {
  -webkit-animation: spinner 24s infinite linear;
  animation: spinner 24s infinite linear; }
.fotorama__wrap--css3 .fotorama__stage .fotorama__img,
.fotorama__wrap--css3 .fotorama__html {
  transition-property: opacity;
  transition-timing-function: linear;
  transition-duration: 0.3s; }

.fotorama__wrap--video .fotorama__stage__frame--video .fotorama__img,
.fotorama__wrap--video .fotorama__stage__frame--video .fotorama__html {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  opacity: 0; }

.fotorama__select {
  cursor: auto; }

.fotorama__video {
  top: 32px;
  right: 0;
  bottom: 0;
  left: 0;
  position: absolute;
  z-index: 10; }

/* Strange fix of the #66 issue (Firefox bug while sliding backwards) */
@-moz-document url-prefix() {
  .fotorama__active {
    box-shadow: 0 0 0 transparent; } }
.fotorama__arr,
.fotorama__fullscreen-icon,
.fotorama__video-play,
.fotorama__video-close {
  position: absolute;
  z-index: 11;
  cursor: pointer; }

.fotorama__arr {
  position: absolute;
  width: 32px;
  height: 32px;
  top: 50%;
  margin-top: -16px; }

.fotorama__arr--prev {
  left: 2px;
  background-position: 0 0; }

.fotorama__arr--next {
  right: 2px;
  background-position: -32px 0; }

.fotorama__arr--disabled {
  pointer-events: none;
  cursor: default;
  *display: none;
  opacity: .1; }

.fotorama__fullscreen-icon {
  width: 32px;
  height: 32px;
  top: 2px;
  right: 2px;
  background-position: 0 -32px;
  z-index: 20; }

.fotorama__fullscreen-icon:focus,
.fotorama__arr:focus {
  border-radius: 50%; }

.fotorama--fullscreen .fotorama__fullscreen-icon {
  background-position: -32px -32px; }

.fotorama__video-play {
  width: 96px;
  height: 96px;
  left: 50%;
  top: 50%;
  margin-left: -48px;
  margin-top: -48px;
  background-position: 0 -64px;
  opacity: 0; }
  .fotorama__wrap--css2 .fotorama__video-play, .fotorama__wrap--video .fotorama__stage .fotorama__video-play {
    display: none; }
  .fotorama__loaded .fotorama__video-play, .fotorama__error .fotorama__video-play, .fotorama__nav__frame .fotorama__video-play {
    opacity: 1;
    display: block; }
  .fotorama__nav__frame .fotorama__video-play {
    width: 32px;
    height: 32px;
    margin-left: -16px;
    margin-top: -16px;
    background-position: -64px -32px; }

.fotorama__video-close {
  width: 32px;
  height: 32px;
  top: 0;
  right: 0;
  background-position: -64px 0;
  z-index: 20;
  opacity: 0; }
  .fotorama__wrap--css2 .fotorama__video-close {
    display: none; }
  .fotorama__wrap--css3 .fotorama__video-close {
    -webkit-transform: translate3d(32px, -32px, 0);
    transform: translate3d(32px, -32px, 0); }
  .fotorama__wrap--video .fotorama__video-close {
    display: block;
    opacity: 1; }
  .fotorama__wrap--css3.fotorama__wrap--video .fotorama__video-close {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }

.fotorama__wrap--no-controls.fotorama__wrap--toggle-arrows .fotorama__arr,
.fotorama__wrap--no-controls.fotorama__wrap--toggle-arrows .fotorama__fullscreen-icon {
  opacity: 0; }
  .fotorama__wrap--no-controls.fotorama__wrap--toggle-arrows .fotorama__arr:focus,
  .fotorama__wrap--no-controls.fotorama__wrap--toggle-arrows .fotorama__fullscreen-icon:focus {
    opacity: 1; }

.fotorama__wrap--video .fotorama__arr,
.fotorama__wrap--video .fotorama__fullscreen-icon {
  opacity: 0 !important; }

.fotorama__wrap--css2.fotorama__wrap--no-controls.fotorama__wrap--toggle-arrows .fotorama__arr,
.fotorama__wrap--css2.fotorama__wrap--no-controls.fotorama__wrap--toggle-arrows .fotorama__fullscreen-icon {
  display: none; }
  .fotorama__wrap--css2.fotorama__wrap--no-controls.fotorama__wrap--toggle-arrows .fotorama__arr:focus,
  .fotorama__wrap--css2.fotorama__wrap--no-controls.fotorama__wrap--toggle-arrows .fotorama__fullscreen-icon:focus {
    display: block; }
.fotorama__wrap--css2.fotorama__wrap--video .fotorama__arr,
.fotorama__wrap--css2.fotorama__wrap--video .fotorama__fullscreen-icon {
  display: none !important; }

.fotorama__wrap--css3.fotorama__wrap--no-controls.fotorama__wrap--slide.fotorama__wrap--toggle-arrows .fotorama__fullscreen-icon:not(:focus) {
  -webkit-transform: translate3d(32px, -32px, 0);
  transform: translate3d(32px, -32px, 0); }
.fotorama__wrap--css3.fotorama__wrap--no-controls.fotorama__wrap--slide.fotorama__wrap--toggle-arrows .fotorama__arr--prev:not(:focus) {
  -webkit-transform: translate3d(-48px, 0, 0);
  transform: translate3d(-48px, 0, 0); }
.fotorama__wrap--css3.fotorama__wrap--no-controls.fotorama__wrap--slide.fotorama__wrap--toggle-arrows .fotorama__arr--next:not(:focus) {
  -webkit-transform: translate3d(48px, 0, 0);
  transform: translate3d(48px, 0, 0); }
.fotorama__wrap--css3.fotorama__wrap--video .fotorama__fullscreen-icon {
  -webkit-transform: translate3d(32px, -32px, 0) !important;
  transform: translate3d(32px, -32px, 0) !important; }
.fotorama__wrap--css3.fotorama__wrap--video .fotorama__arr--prev {
  -webkit-transform: translate3d(-48px, 0, 0) !important;
  transform: translate3d(-48px, 0, 0) !important; }
.fotorama__wrap--css3.fotorama__wrap--video .fotorama__arr--next {
  -webkit-transform: translate3d(48px, 0, 0) !important;
  transform: translate3d(48px, 0, 0) !important; }

.fotorama__wrap--css3 .fotorama__arr:not(:focus),
.fotorama__wrap--css3 .fotorama__fullscreen-icon:not(:focus),
.fotorama__wrap--css3 .fotorama__video-play:not(:focus),
.fotorama__wrap--css3 .fotorama__video-close:not(:focus) {
  transition-property: -webkit-transform, opacity;
  transition-property: transform, opacity;
  transition-duration: 0.3s; }

.fotorama__stage:before, .fotorama__stage:after, .fotorama__nav:before, .fotorama__nav:after {
  content: "";
  display: block;
  position: absolute;
  text-decoration: none;
  top: 0;
  bottom: 0;
  width: 10px;
  height: auto;
  z-index: 10;
  pointer-events: none;
  background-repeat: no-repeat;
  background-size: 1px 100%, 5px 100%; }
.fotorama__stage:before, .fotorama__nav:before {
  background-image: linear-gradient(transparent, rgba(0, 0, 0, 0.2) 25%, rgba(0, 0, 0, 0.3) 75%, transparent), radial-gradient(farthest-side at 0 50%, rgba(0, 0, 0, 0.4), transparent);
  background-position: 0 0, 0 0;
  left: -10px; }
.fotorama__stage.fotorama__shadows--left:before, .fotorama__nav.fotorama__shadows--left:before {
  left: 0; }
.fotorama__stage:after, .fotorama__nav:after {
  background-image: linear-gradient(transparent, rgba(0, 0, 0, 0.2) 25%, rgba(0, 0, 0, 0.3) 75%, transparent), radial-gradient(farthest-side at 100% 50%, rgba(0, 0, 0, 0.4), transparent);
  background-position: 100% 0, 100% 0;
  right: -10px; }
.fotorama__stage.fotorama__shadows--right:after, .fotorama__nav.fotorama__shadows--right:after {
  right: 0; }

.fotorama--fullscreen .fotorama__stage:before, .fotorama--fullscreen .fotorama__stage:after,
.fotorama--fullscreen .fotorama__nav:before,
.fotorama--fullscreen .fotorama__nav:after,
.fotorama__wrap--no-shadows .fotorama__stage:before,
.fotorama__wrap--no-shadows .fotorama__stage:after,
.fotorama__wrap--no-shadows .fotorama__nav:before,
.fotorama__wrap--no-shadows .fotorama__nav:after,
.fotorama__wrap--fade .fotorama__stage:before,
.fotorama__wrap--fade .fotorama__stage:after {
  display: none; }
