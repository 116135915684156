.faq {
  padding: 55px 33px;
  @media(max-width: $screen-md-min) {
    padding-left: 0;
    padding-right: 0; } }

.faq__title {}

.faq__list {
  margin-top: 40px; }
