.benefit_steps {
  padding: 50px 112px;
  @media(max-width: $screen-lg-min) {
    padding: 40px 0; } }

.benefit_steps__title {
  margin-bottom: 40px; }

.benefit_steps__steps {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin-bottom: 50px;
  @media(max-width: 750px) {
    flex-direction: column;
    align-items: flex-start; } }

.benefit_steps__step {
  flex: 1;
  color: #fff;
  padding: 18px 30px;
  font-size: 14px;
  & + .benefit_steps__step {
    margin-left: 2px;
    @media(max-width: 750px) {
      margin-left: 0;
      margin-top: 2px; } }
  &.first {
    background: #9c9c9c;
    @media(max-width: 750px) {
      width: 75%; }
    @media(max-width: 500px) {
      width: 100%; } }
  &.second {
    background: $brand-primary;
    padding-bottom: 78px;
    @media(max-width: 750px) {
      padding-bottom: inherit;
      width: 90%; }
    @media(max-width: 500px) {
      width: 100%; } }
  &.third {
    background: $brand-danger;
    padding-bottom: 150px;
    @media(max-width: 750px) {
      padding-bottom: inherit;
      width: 100%; } } }

.benefit_steps__step__title {
  font-size: 21px;
  font-weight: bold; }
