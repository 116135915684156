.pagination {
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: $screen-md-min) {
    display: block; } }


.pagination__more {
  .btn {
    font-weight: bold;
    min-width: 170px;
    color: $link-color; }
  @media (max-width: $screen-md-min) {
    flex: 1;
    clear: both;
    margin-bottom: 20px;
    text-align: center; } }

.pagination__pages {
  @media (max-width: $screen-md-min) {
    text-align: center;
    & .btn-group {
      float: none; } } }

.pagination__limit {
  min-width: 170px;
  & > .dropdown {
    width: 100%;
    .btn {
      width: 100%; } }
  & .dropdown-menu {
    width: 100%; }
  @media (max-width: $screen-md-min) {
    display: none; } }
