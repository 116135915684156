.team {
  padding: 50px 33px 50px; }

.team__title {
  margin-bottom: 40px; }

.team__member {
  & + .team__member {
    margin-top: 87px; } }

.team__member__photo {
  img {
    max-width: 100%; } }

.team__member__name {
  font-size: 21px;
  font-weight: bold;
  margin-bottom: 3px; }

.team__member__position {
  font-style: italic;
  color: $brand-danger;
  margin-bottom: 22px; }

.team__member__text {
  font-family: "Freeset"; }
