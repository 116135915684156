
.owl-theme {
	// Styling Next and Prev buttons
	.owl-nav {
		margin-top: 10px;
		text-align: center;
		-webkit-tap-highlight-color: transparent;

		[class*='owl-'] {
			margin: $nav-margin;
			padding: $nav-padding;
			display: inline-block;
			cursor: pointer;
			position: absolute;
			z-index: 3;
			@include box-shadow(0 0 5px rgba(0,0,0,0.3));
			border-radius: 100%;

			&:hover {
				text-decoration: none;
			}
		}
		> .owl-prev{
			left: -32px;
			top: 50%;
			margin-top: -32px
		}
		> .owl-next{
			right: -32px;
			top: 50%;
			margin-top: -32px
		}
		.disabled {
			opacity: $nav-disabled-opacity;
			cursor: default;
		}
	}

	// Styling dots
	.owl-nav.disabled + .owl-dots {
		margin-top: 10px;
	}

	.owl-dots {
		text-align: center;
		-webkit-tap-highlight-color: transparent;
		position: absolute;
		bottom: 20px;
		width: 100%;

		.owl-dot {
			display: inline-block;
			zoom: 1;
			*display: inline;

			span {
				width: $dot-width;
				height: $dot-height;
				margin: $dot-margin;
				background: $dot-background;
				display: block;
				-webkit-backface-visibility: visible;
				transition: opacity 200ms ease;
				border-radius: 100%;
				border: 3px solid #fff;
			}

			&.active,
			&:hover {
				span {
					background: $dot-background-active;
					border: 3px solid #fff;
				}
			}
		}
	}
}
