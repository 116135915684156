.header {}

.header__top {
  background: #f9f7fa;
  border-top: 1px solid #e3e3e3;
  border-bottom: 1px solid #e3e3e3;
  padding: 3px 0;
  @media (max-width: $screen-sm-min) {
    display: none; } }

.header__top__links {}

.header__top__link {
  color: #7c7b7c;
  font-size: 14px;
  text-decoration: none;
  &.active {
    color: #000000; }
  &:hover {
    color: #000;
    text-decoration: none; }
  & + .header__top__link {
    margin-left: 28px; } }

.header__main {
  padding: 23px 0; }

.header__main__row {
  @media (max-width: $screen-sm-min) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    & > div {
      flex: 1; } } }

.header__main__altmenu {
  .navbar-toggle {
    margin: 0; }
  .icon-bar {
    background: #696969; } }

.header__logo {
  @media (max-width: $screen-md-min) {
    display: block;
    margin: 0 auto 20px;
    text-align: center; }
  @media (max-width: $screen-sm-min) {
    display: inline;
    margin: 0;
    & img {
      width: 154px; } } }

.header__phone {
  padding-left: 44px;
  & > span {
    color: #7c7b7c;
    font-size: 12px;
    font-family: "Freeset";
    font-weight: 300; }
  & > a {
    font-size: 21px; }
  @media (max-width: $screen-lg-min) {
    & > a {
      font-size: 16px; } } }

.header__email {
  & > span {
    color: #7c7b7c;
    font-size: 12px;
    font-family: "Freeset";
    font-weight: 300; }
  & > a {
    font-size: 21px; }
  @media (max-width: $screen-lg-min) {
    & > a {
      font-size: 16px; } } }

.header__user {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  &.not-login {
    padding-top: 6px; } }

.header__user__login {
  display: flex;
  align-items: center;
  justify-content: center;
  & > span {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: bold;
    margin-top: 2px; }
  & > img {
    margin-right: 10px; } }

.header__user__info {
  text-align: right;
  @media (max-width: $screen-sm-min) {
    display: none; } }

.header__user__username {
  font-size: 14px;
  color: #000000;
  text-transform: uppercase; }

.header__user__actions {
  & > a {
    font-size: 14px;
    text-decoration: underline;
    &:hover {
      text-decoration: none; }
    & + a {
      margin-left: 15px; } } }

.header__user__avatar {
  background: #f9f7fa;
  border-radius: 100%;
  width: 62px;
  height: 62px;
  margin-left: 10px;
  @media (max-width: $screen-md-min) {
    width: auto;
    height: auto; }
  &.no-avatar {
    padding: 16px;
    @media (max-width: $screen-md-min) {
      padding: 0; } } }
