//
// Breadcrumbs
// --------------------------------------------------


.breadcrumb {
  padding: $breadcrumb-padding-vertical $breadcrumb-padding-horizontal $breadcrumb-padding-vertical 0;
  margin-bottom: $line-height-computed / 3;
  list-style: none;
  background-color: $breadcrumb-bg;

  > li {
    display: inline-block;
    font-size: 12px;
    + li:before {
      // [converter] Workaround for https://github.com/sass/libsass/issues/1115
      $nbsp: "\00a0";
      content: "#{$breadcrumb-separator}#{$nbsp}"; // Unicode space added since inline-block means non-collapsing white-space
      padding: 0 3px;
      color: $breadcrumb-color;
    }
    a {
      color: $breadcrumb-color;
    }
  }

  > .active {
    color: $breadcrumb-active-color;
  }
}
