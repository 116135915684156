.qote {
  width: 100%;
  background: #fff;
  border-radius: 95px;
  padding: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media(max-width: $screen-md-min) {
    border-radius: 5px;
    padding: 15px; } }

.qote-bordered {
  border: 1px solid #e6e9f0; }

.qote__avatar {
  width: 150px;
  height: 150px;
  min-width: 150px;
  border-radius: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #eee;
  position: relative; }

.qote__icon {
  position: absolute;
  right: 15px;
  top: 0; }

.qote__content {
  text-align: left;
  padding-left: 25px;
  .qote__avatar {
    width: 55px;
    height: 55px;
    min-width: 55px;
    border-radius: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-color: #eee;
    position: relative;
    float: left;
    margin-bottom: 13px;
    margin-right: 12px;
    .qote__icon {
      position: absolute;
      right: 0px;
      top: 0;
      width: 15px; } } }

.qote__author {
  font-size: 21px; }

.qote__from {
  color: $link-color;
  font-style: italic;
  @media(max-width: $screen-md-min) {
    display: block;
    clear: both; } }

.qote__text {
  margin-top: 10px;
  font-family: "Freeset";
  @media(max-width: $screen-md-min) {
    display: block;
    clear: both; } }
