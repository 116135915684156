.gallery {
  margin-top: 46px;
  margin-bottom: 36px; }

.gallery__row {
  padding: 0;
  margin: 0;
  list-style: none;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row;
  justify-content: space-around;
  line-height: 30px; }

.gallery__item {
  margin: 0px;
  color: white;
  font-weight: bold;
  flex: 1 0 auto;
  height: auto;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  &:before {
    content: '';
    float: left;
    padding-top: 100%; }
  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: 0.5;
    @include transition(opacity .15s linear); }
  &:hover {
    &:after {
      @include transition(opacity .15s linear);
      opacity: 0; } } }
