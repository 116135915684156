.counters {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 30px;
  @media(max-width: $screen-md-min) {
    flex-direction: column; } }

.counter {
  display: flex;
  align-items: center;
  justify-content: center;
  @media(max-width: $screen-md-min) {
    & + .counter {
      margin-top: 20px; } } }


.counter__number {
  color: $link-color;
  font-size: 60px;
  font-weight: bold;
  line-height: 62px;
  margin-bottom: -9px;
  margin-right: 7px;
  @media(max-width: $screen-md-min) {
    font-size: 30px; } }

.counter__number-sm {
  font-size: 40px;
  @media(max-width: $screen-md-min) {
    font-size: 25px; } }

.counter__text {
  font-size: 18px;
  font-weight: 300;
  @media(max-width: $screen-md-min) {
    font-size: 14px; } }
