.newsletter_form {
  padding: 38px 38px 14px;
  border: 1px solid #e3e3e3;
  border-radius: 5px;
  margin-top: 40px;
  @media (max-width: $screen-lg-min) {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 15px;
    text-align: center; }
  & .form-control {
    margin-right: 15px; } }

.newsletter_form__title {
  font-weight: bold;
  font-size: 21px;
  line-height: 60px;
  @media (max-width: $screen-lg-min) {
    display: block;
    text-align: center;
    line-height: 33px;
    margin-bottom: 20px; } }

.newsletter_form__notice {
  display: block;
  font-size: 12px;
  text-align: right;
  margin-top: 15px;
  @media (max-width: $screen-lg-min) {
    text-align: center; }
  a {
    text-decoration: underline;
    &:hover {
      text-decoration: none; } } }

.marketing__instruments {
  padding: 0 33px;
  margin-top: 50px;
  margin-bottom: 40px;
  @media (max-width: $screen-lg-min) {
    padding: 0; } }

.newsletter_form__form {
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 480px) {
    flex-direction: column; }
  & .form-group {
    flex: 1;
    padding-right: 20px;
    @media (max-width: 480px) {
      padding-right: 0; }
    & > input {
      width: 100%; } }
  @media (max-width: 480px) {
    & .btn {
      flex: 1;
      clear: both; } } }
