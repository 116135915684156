.slider {
  margin-top: 57px;
  @media(max-width: $screen-sm-min) {
    margin-left: -15px;
    margin-right: -15px;
    width: calc(100% + 30px)!important; } }

.slider__item {
  background: $brand-primary;
  padding: 40px 90px 71px 90px;
  width: 100%;
  color: #fff;
  min-height: 360px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  @media(max-width: $screen-md-min) {
    padding: 40px 20px 71px 20px; } }

.slider__item__title {
  font-size: 43px;
  font-weight: bold;
  margin-bottom: 31px;
  @media(max-width: $screen-md-min) {
    font-size: 28px; } }

.slider__item__text {
  text-align: center;
  font-family: "Freeset";
  font-weight: 300;
  & > * {
    & + * {
      margin-top: 34px; } }
  & > *:last-child {
    margin-bottom: 0; } }
