$goal_border: #e6e9f0;
$goal_border-radius: 10px;

.goals {
  width: 100%;
  margin-top: 35px;
  padding: 0 33px 40px;
  @media(max-width: $screen-md-min) {
    padding-left: 0;
    padding-right: 0; } }

.goal {
  width: 100%;
  padding: 21px 28px;
  border: 1px solid $goal_border;
  border-radius: $goal_border-radius;
  display: flex;
  align-items: center;
  justify-content: center;
  & + .goal {
    margin-top: 19px; }
  @media(max-width: $screen-md-min) {
    padding-left: 15px;
    padding-right: 15px; } }

.goal__content {
  flex: 1;
  padding-left: 40px;
  @media(max-width: $screen-md-min) {
    padding-left: 0; } }

.goal__title {
  font-size: 21px;
  font-weight: bold;
  color: $link-color;
  display: block;
  margin-bottom: 10px;
  @media(max-width: $screen-md-min) {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 18px;
    img {
      margin-right: 10px; } } }

.goal__text {
  font-family: "Freeset"; }
