.mini_cart {
  display: flex;
  align-items: center;
  height: 60px;
  margin-right: $navbar-padding-horizontal; }

.mini_cart__data {
  text-align: right;
  padding-top: 3px; }

.mini_cart__title {
  color: #7c7b7c;
  font-size: 12px;
  font-family: "Freeset";
  font-weight: 300;
  display: block;
  margin-bottom: -6px; }

.mini_cart__totalcost {
  color: #000;
  font-size: 21px; }

.mini_cart__icon {
  position: relative;
  width: 24px;
  margin-left: 10px; }

.mini_cart__total {
  position: absolute;
  right: -7px;
  top: -4px;
  background: $brand-danger;
  border: 1px solid #fff;
  color: #fff;
  font-size: 10px;
  font-family: "Freeset";
  font-weight: bold;
  padding: 1px;
  border-radius: 100%; }
