.sheme__path {
  margin: 71px 0 112px;
  @media (max-width: $screen-sm-min) {
    margin-bottom: 40px;
    margin-top: 30px; } }

.sheme__path__steps {
  position: relative;
  @media (min-width: $screen-lg-min) {
    width: 43%;
    margin-left: 57%; }
  @media (max-width: $screen-lg-min) {
    width: 43%;
    margin-left: 57%; }
  @media (max-width: $screen-md-min) {
    width: 70%;
    margin-left: 30%; }
  @media (max-width: $screen-sm-min) {
    width: calc(100% - 90px);
    margin-left: 90px; } }

.sheme__path__img {
  position: absolute;
  right: calc(100% + 17px);
  top: -39px;
  @media (max-width: $screen-sm-min) {
    width: auto;
    height: 340px;
    top: -13px; } }

.sheme__path__comment {
  position: absolute;
  left: -146%;
  top: 50%;
  margin-top: -29px;
  line-height: 28px;
  @media (max-width: $screen-sm-min) {
    width: auto;
    height: 340px;
    top: -13px; } }

.sheme__path__step {
  text-align: left;
  font-size: 14px;
  font-weight: 300;
  @media (max-width: $screen-sm-min) {
    font-size: 12px;
    height: 44px; }
  b {
    display: block;
    clear: both;
    font-size: 19px;
    @media (max-width: $screen-sm-min) {
      font-size: 14px; } }
  & + .sheme__path__step {
    margin-top: 90px;
    @media (max-width: $screen-lg-min) {
      margin-top: 71px; }
    @media (max-width: $screen-md-min) {
      margin-top: 92px; }
    @media (max-width: $screen-sm-min) {
      margin-top: 25px; } } }
