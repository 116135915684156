section {}

.section__title {
  font-size: 43px;
  font-weight: bold;
  text-align: left;
  text-transform: uppercase;
  line-height: 54px;

  &.-small {
    font-size: 21px;
    line-height: 32px; }

  &.-centered {
    text-align: center; }

  &.-red {
    color: $brand-danger; }

  &.-blue {
    color: $link-color; }

  &.-white {
    color: #fff; }

  &.-normal {
    text-transform: none; }

  &.-shadowed {
    text-shadow: 1px 1px 6px rgba(0,0,0,0.4); }
  @media(max-width: $screen-md-min) {
    font-size: 28px;
    line-height: 39px; } }
