.sheme {
  padding: 50px 33px 65px;
  @media(max-width: $screen-md-min) {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0; } }

.sheme__qote_wrapper {
  width: 100%;
  padding: 0 117px;
  margin-top: 103px;
  @media(max-width: $screen-md-min) {
    padding: 0;
    margin-top: 50px; } }

.sheme__images {
  display: flex;
  margin-top: 109px;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  & > img {
    margin-bottom: 30px;
    max-height: 56px;
    & + img {
      margin-left: 48px; } }
  @media(max-width: $screen-md-min) {
    margin-top: 50px;
    & > img {
      margin-bottom: 30px;
      margin-right: 15px;
      max-height: 36px;
      & + img {
        margin-left: 0; } } } }
