.sharing {
  width: 100%;
  padding: 30px 40px;
  border: 1px solid $brand-danger;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: $screen-lg-min) {
    padding: 20px;
    flex-direction: column;
    align-items: flex-start; }
  margin-bottom: 30px; }

.sharing__title {
  color: $brand-danger;
  font-weight: bold;
  font-size: 18px;
  @media (max-width: $screen-lg-min) {
    margin-bottom: 20px; } }

.sharing__links {
  @media (max-width: $screen-lg-min) {
    text-align: left; } }
