.article {}

.article__meta {
  display: inline-block;
  width: 100%;
  & > div {
    color: #7c7b7c;
    font-size: 12px;
    font-family: "Freeset";
    float: left;
    & + div {
      margin-left: 68px;
      @media (max-width: $screen-md-min) {
        margin-left: 36px; } } }
  + .content__text {
    margin-top: 10px; } }

.article__tags {
  width: 100%;
  margin-bottom: 30px;
  & > a {
    margin-bottom: 10px;
    margin-right: 10px; } }
