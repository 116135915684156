.hero {
  padding: 30px 76px 30px;
  text-align: center;
  @media(max-width: $screen-md-min) {
    padding-right: 0;
    padding-left: 0; } }

.hero__title {
  text-align: center;
  font-size: 61px;
  text-shadow: 1px 1px 6px rgba(255,255,255,0.4);
  font-weight: bold;
  @media(max-width: $screen-md-min) {
    font-size: 43px; }
  @media(max-width: $screen-sm-min) {
    font-size: 26px;
    line-height: 36px; } }

.hero__play {
  width: 88px;
  height: 88px;
  background: rgba(255,255,255,0.3);
  border-radius: 100%;
  padding: 9px;
  box-shadow: 0 0 5px rgba(0,0,0,0.3);
  position: relative;
  margin: 20px auto 0;
  cursor: pointer;
  &::before {
    content: '';
    position: absolute;
    left: 9px;
    top: 9px;
    background: #000;
    width: 70px;
    height: 70px;
    border-radius: 100%;
    z-index: 1; }
  &::after {
    content: '';
    position: absolute;
    z-index: 2;
    width: 22px;
    height: 22px;
    top: 50%;
    margin-top: -11px;
    left: 50%;
    margin-left: -7px;
    background: url('/assets/img/icons/play.png');
    background-size: contain; } }


.hero__subtitle {
  font-size: 21px;
  margin-bottom: 38px;
  margin-top: 38px; }

.hero__btn {}
