.products {
  margin-top: 30px;
  & > .row > div {
    &:nth-child(4n+1) {
      clear: left; } }
  @media(max-width: $screen-lg-min) {
    & > .row > div {
      &:nth-child(4n+1) {
        clear: inherit; }
      &:nth-child(3n+1) {
        clear: left; } } }
  @media(max-width: $screen-md-min) {
    & > .row > div {
      &:nth-child(4n+1) {
        clear: inherit; }
      &:nth-child(3n+1) {
        clear: inherit; }
      &:nth-child(2n+1) {
        clear: left; } } } }
.product {
  width: 100%;
  margin-bottom: 40px;
  position: relative;
  &:before {
    content: '';
    position: absolute;
    z-index: -1;
    opacity: 1; }
  &:hover {
    z-index: 10;
    .product__form__hover {
      transition: all .15s ease-in-out;
      z-index: 3;
      opacity: 1;
      box-shadow: 0 7px 22px rgba(41, 45, 56, 0.12); } } }

.product__form {
  position: relative; }

.product__form__main {
  position: relative;
  z-index: 4; }

.product__form__hover {
  position: absolute;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding-bottom: 15px;
  padding-right: 15px;
  padding-left: 15px;
  left: -15px;
  top: -3px;
  width: calc(100% + 30px);
  height: calc(122% + 30px);
  background: #fff;
  opacity: 0;
  z-index: -1;
  transition: all .15s ease-in-out;
  border: 1px solid #e3e3e3; }

.product__art {
  text-align: right;
  font-size: 12px;
  font-family: "Freeset"; }

.product__flags {
  position: absolute;
  left: 10px;
  top: 25px; }

.product__flag {
  &.popular {
    background: $brand-primary;
    text-transform: uppercase;
    color: #fff;
    font-size: 12px;
    font-family: "Freeset";
    padding: 4px 5px 2px; } }

.product__thumb {
  width: 100%;
  height: 140px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  &.no-image {
    background-image: url("/assets/img/logo.png");
    background-color: #f9f7fa;
    background-size: 69%;
    background-repeat: no-repeat;
    background-position: center; } }

.product__meta {
  font-size: 12px;
  font-family: "Freeset";
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media(max-width: 500px) {
    font-size: 9px; } }

.product__title {
  font-size: 14px;
  text-decoration: underline;
  @media(max-width: 500px) {
    font-size: 12px; } }

.product__price {
  padding-top: 6px; }

.product__old_price {
  font-family: "Freeset";
  font-size: 16px;
  color: #7c7b7c;
  @media(max-width: 500px) {
    font-size: 14px; } }

.product__old_price__value {
  line-height: 25px;
  text-decoration: line-through;
  display: block;
  float: left;
  font-style: italic;
  font-weight: normal;
  @media(max-width: 500px) {
    line-height: 18px; } }

.product__benefit {
  color: $text-color;
  background: #f0bf8b;
  border-radius: 2px;
  padding: 5px 2px 3px;
  font-size: 12px;
  margin-left: 13px;
  position: relative;
  max-height: 20px;
  line-height: 13px;
  &:after {
    content: '';
    position: absolute;
    left: -4px;
    top: 50%;
    margin-top: -2px;
    width: 4px;
    height: 4px;
    border-radius: 100%;
    background: #fff; }
  &:before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    left: -10px;
    top: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 10px solid #f0bf8b; } }

.product__actual_price {
  clear: both;
  display: block;
  font-size: 14px;
  span {
    font-size: 21px; }
  @media(max-width: 500px) {
    font-size: 12px;
    span {
      font-size: 18px; } } }

.product__buy {
  font-size: 14px;
  padding: 10px 11px 9px;
  font-weight: bold;
  @media(max-width: 500px) {
    font-size: 12px;
    padding: 10px 7px 9px; } }

.product__form__count {}

.product__form__count_input {
  min-height: 40px;
  text-align: center;
  width: 50px;
  @media(max-width: 500px) {
    width: auto; } }

.product__form__count__minus {
  background: none;
  border: none;
  box-shadow: none; }

.product__form__count__plus {
  background: none;
  border: none;
  box-shadow: none; }
