.blog {}

.blog__item {
  margin-bottom: 30px; }

.blog__item__title {
  font-size: 18px;
  margin-bottom: 5px; }

.blog__item__image {
  height: 236px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover; }

.blog__item__meta {
  padding: 5px 0;
  margin-bottom: 5px;
  & * {
    color: #7c7b7c;
    font-size: 12px;
    font-family: "Freeset"; } }

.blog__item__introtext {
  font-family: "Freeset";
  margin-bottom: 10px; }

.blog__item__readmore {
  text-decoration: underline;
  &:hover {
    text-decoration: none; } }
