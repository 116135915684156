@font-face {
  font-family: 'Rub';
  src: url("../fonts/RUBSN.otf") format('opentype');
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'PFBeauSansPro';
  src: url("../fonts/font-pf-beau-sans-pro/PFBeauSansPro-Thin.otf") format('opentype');
  font-weight: 100;
  font-style: normal;
  font-stretch: normal; }

@font-face {
  font-family: 'PFBeauSansPro';
  src: url("../fonts/font-pf-beau-sans-pro/PFBeauSansPro-Light.otf") format('opentype');
  font-weight: 300;
  font-style: normal;
  font-stretch: normal; }

@font-face {
  font-family: 'PFBeauSansPro';
  src: url("../fonts/font-pf-beau-sans-pro/PFBeauSansPro-Regular.otf") format('opentype');
  font-weight: 400;
  font-style: normal;
  font-stretch: normal; }

@font-face {
  font-family: 'PFBeauSansPro';
  src: url("../fonts/font-pf-beau-sans-pro/PFBeauSansPro-SemiBold.otf") format('opentype');
  font-weight: 600;
  font-style: normal;
  font-stretch: normal; }

@font-face {
  font-family: 'PFBeauSansPro';
  src: url("../fonts/font-pf-beau-sans-pro/PFBeauSansPro-Bold.otf") format('opentype');
  font-weight: bold;
  font-style: normal;
  font-stretch: normal; }

@font-face {
  font-family: 'Freeset';
  src: url("../fonts/freeset/FreeSetLightC.otf") format('opentype');
  font-weight: 300;
  font-style: normal;
  font-stretch: normal; }

@font-face {
  font-family: 'Freeset';
  src: url("../fonts/freeset/FreeSetC.otf") format('opentype');
  font-weight: 400;
  font-style: normal;
  font-stretch: normal; }

@font-face {
  font-family: 'Freeset';
  src: url("../fonts/freeset/FreeSetDemiC.otf") format('opentype');
  font-weight: 600;
  font-style: normal;
  font-stretch: normal; }

@font-face {
  font-family: 'Freeset';
  src: url("../fonts/freeset/FreeSetDemiC-Italic.otf") format('opentype');
  font-weight: 600;
  font-style: italic;
  font-stretch: normal; }

@font-face {
  font-family: 'Freeset';
  src: url("../fonts/freeset/FreeSetBoldC.otf") format('opentype');
  font-weight: bold;
  font-style: normal;
  font-stretch: normal; }

@import "bootstrap/bootstrap.scss";
@import "blocks/all";
@import "vendors/all";
