.sort {
  font-size: 14px; }

.sort__link {
  margin-left: 30px;
  &.active {
    color: $brand-danger;
    margin-right: 10px;
    & > span {
      position: relative;
      &:before {
        content: '';
        width: 6px;
        height: 5px;
        position: absolute;
        background-size: contain;
        background-position: center;
        top: 50%;
        margin-top: -3px;
        right: -10px; } }
    &[data-dir="desc"] {
      & > span {
        &:before {
          background-image: url('/assets/img/icons/icon-sort-down.png'); } } }
    &[data-dir="asc"] {
      & > span {
        &:before {
          background-image: url('/assets/img/icons/icon-sort-up.png'); } } } }
  & + .sort__link {} }
