.map {
  position: relative; }

.map__google {
  height: 486px;
  width: 100%;
  position: relative;
  z-index: 4; }

.map__legend {
  position: absolute;
  left: 84px;
  top: 90px;
  z-index: 10;
  background: #fff;
  padding: 20px 32px;
  @include box-shadow(0 0px 5px rgba(0,0,0,.3));
  & p:last-child {
    margin-bottom: 0; } }
