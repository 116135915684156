.brands {
  margin-top: 20px;
  margin-bottom: 40px; }

.brand {
  position: relative;
  width: 100%;
  margin-bottom: 25px; }

.brand__link {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%; }

.brand__image {
  width: 100%;
  height: 200px;
  border: 1px solid #e3e3e3;
  background-position: center;
  background-size: auto;
  background-repeat: no-repeat;
  margin-bottom: 10px;
  @media (max-width: $screen-md-min) {
    height: 110px;
    background-size: contain; } }

.brand__title {
  color: $brand-primary;
  font-size: 18px;
  @media (max-width: $screen-md-min) {
    font-size: 15px; } }
