.promo_footer {
  padding: 55px 200px;
  text-align: center;
  @media (max-width: $screen-lg-min) {
    padding: 55px 0; }
  a:not(.btn) {
    text-decoration: underline;
    &:hover {
      text-decoration: none; } }
  @media(max-width: $screen-md-min) {
    padding-left: 0;
    padding-right: 0; } }

.promo_footer__form {
  margin-bottom: 51px;
  margin-top: 30px;
  & button {
    margin-top: 35px; } }

.promo_footer__meta {
  font-size: 14px; }
