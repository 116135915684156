.content {
  position: relative;
  padding-bottom: 40px;
  &.no-padding {
    padding-bottom: 0; } }


.content-mainpage {
  padding: 0;
  &::before {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    width: 100%;
    height: 595px;
    background-image: url('/assets/img/mainpage/backgroun.jpg');
    background-position: center bottom;
    background-size: cover; }
  &::after {
    content: '';
    top: 0;
    position: absolute;
    z-index: 0;
    width: 100%;
    height: 596px;
    @include gradient-directional(rgba(255,255,255,0.01), rgba(255,255,255,1), 180deg); }
  & > .container {
    z-index: 3;
    position: relative; } }

.content__text {
  font-family: "Freeset";
  margin-top: 30px;
  img {
    &.pull-left {
      margin-right: 18px;
      margin-bottom: 18px; } } }
