.item {}

.item__title {
  margin-top: 0; }

.item__subtitle {
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 15px; }

.item__gallery {
  margin-bottom: 15px;
  position: relative;
  .product__flags {
    z-index: 4;
    top: 11px; } }

.item__options {
  margin-bottom: 30px; }

.item__similar {
  & .row {
    & > div {
      &:nth-child(2n+1) {
        clear: left; } } } }

.item__form {
  margin-bottom: 30px; }

.item__form__blocks {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &.mobile {
    align-items: flex-start;
    flex-direction: column;
    & .item__form__block_price {
      margin-bottom: 15px; } } }

.item__form__block_price_count {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  & .product__buy {
    margin-left: 25px;
    padding-left: 16px;
    padding-right: 15px;
    font-size: 14px;
    img {
      margin-right: 12px;
      margin-top: -2px; } } }

.item__partners__pdf {
  clear: both;
  font-family: "Freeset";
  font-size: 16px;
  padding-left: 52px;
  position: relative;
  display: block;
  line-height: 32px;
  margin-bottom: 27px;
  color: $text-color;
  text-decoration: none;
  &:hover {
    text-decoration: none; }
  span {
    border-bottom: 1px solid #bfbfbf; }
  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 32px;
    height: 32px;
    background-image: url('/assets/img/icons/pdf-colored.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center; } }

.item__meta {
  margin: -8px 0 16px;
  color: #777777;
  font-size: 14px; }

.item__art {
  margin-right: 20px; }

.item__partners {
  margin-bottom: 30px;
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .item__subtitle {
    display: flex;
    align-self: flex-start;
    clear: both;
    width: 100%;
    min-width: 100%; } }

.item__partner {
  flex: 1;
  position: relative;
  & + .item__partner {
    margin-left: 6px; }
  &:hover {
    .item__partner__hover {
      opacity: 1;
      transition: all .2s ease; } } }

.item__partner__link {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 4; }

.item__partner__thumb {
  position: relative;
  width: 100%;
  min-height: 118px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin-bottom: 6px; }

.item__partner__hover {
  color: #fff;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: all .2s ease;
  background: rgba(0,0,0,0.6);
  padding: 15px;
  font-size: 14px; }

.item__partner__price {
  span {
    font-size: 21px; } }

.item__partner__art {
  display: block;
  text-align: center;
  font-size: 12px;
  font-family: "Freeset"; }
