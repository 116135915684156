.big_team {
  padding: 66px 33px 30px; }

.big_team__title {
  color: $brand-danger;
  font-size: 43px;
  font-weight: bold;
  margin-bottom: 0; }

.big_team__subtitle {
  font-size: 21px;
  font-weight: bold;
  position: relative; }

.big_team__map {
  max-width: 100%;
  margin-top: -56px;
  margin-bottom: 44px; }
