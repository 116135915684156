.rp_open {}

.rp_open__h1 {
  font-size: 54px;
  line-height: 63px;
  color: #fff;
  text-shadow: 1px 1px 6px rgba(0,0,0,0.4);
  margin-bottom: 30px;
  letter-spacing: 1px;
  @media(max-width: $screen-md-min) {
    font-size: 36px;
    line-height: initial; }
  @media(max-width: $screen-sm-min) {
    font-size: 36px;
    line-height: initial; } }


.rp_open_1 {
  padding: 60px 0;
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: cover;
  position: relative;
  &::after {
    content: '';
    top: 0;
    position: absolute;
    z-index: 0;
    width: 100%;
    height: 100%;
    @include gradient-directional(rgba(0,0,0,0.01), rgba(0,0,0,0.47), 180deg); }
  & > .container {
    z-index: 3;
    position: relative; }
  .consult_form {
    margin-top: 0;
    @media(max-width: $screen-md-min) {
      width: 100%; } }
  .consult_form__text__comment {
    font-size: 21px;
    color: #fff;
    font-weight: 300;
    @media(max-width: $screen-md-min) {
      font-size: 18px; } }

  .consult_form__form__title {
    font-weight: bold;
    font-size: 21px;
    line-height: 26px;
    display: block;
    text-align: center;
    margin-bottom: 17px; } }

.rp_open_2 {
  padding: 50px 33px;
  @media(max-width: $screen-lg-min) {
    padding: 40px 0; } }

.rp_open_3 {
  padding-bottom: 40px; }

.rp_open_3__title {
  font-size: 21px;
  text-align: center;
  margin-bottom: 28px; }

.rp_open_4 {
  background: $brand-danger;
  padding: 70px 0px 40px;
  .consult_form {
    margin-top: 0;
    @media(max-width: $screen-md-min) {
      width: 100%; }
    .consult_form__form {
      &::before {
        content: '';
        position: absolute;
        left: -122px;
        top: inherit;
        bottom: 0;
        width: 410px;
        height: 501px;
        z-index: 2;
        background: url('/assets/img/consult/bg.png');
        background-repeat: no-repeat;
        background-size: contain; } } }
  .consult_form__text__comment {
    font-size: 16px;
    color: #fff;
    font-weight: 300;
    @media(max-width: $screen-md-min) {
      font-size: 14px; } }

  .consult_form__form__title {
    font-weight: bold;
    font-size: 21px;
    line-height: 24px;
    display: block;
    text-align: center;
    margin-bottom: 15px; }
  .consult_form__text {
    position: relative;
    z-index: 4; } }

.rp_open_5 {
  background: $brand-danger;
  padding: 50px 0px 66px;
  margin-top: 50px;
  overflow: hidden;
  & > .container {
    position: relative; }
  .help__manager {
    z-index: 0;
    right: -156px;
    top: 193px;
    @media (max-width: $screen-md-min) {
      width: 422px;
      right: -149px;
      top: 300px; }
    @media (max-width: $screen-sm-min) {
      display: none; } }
  .consult_form__form {
    width: 390px;
    margin: 0 auto;
    max-width: 100%;
    &::before {
      content: '';
      position: absolute;
      left: -122px;
      top: inherit;
      bottom: 0;
      width: 410px;
      height: 501px;
      z-index: 2;
      background: url('/assets/img/consult/bg.png');
      background-repeat: no-repeat;
      background-size: contain; } }
  .consult_form__form__title {
    font-weight: bold;
    font-size: 21px;
    line-height: 24px;
    display: block;
    text-align: center;
    margin-bottom: 15px; } }

.rp_open_5__title {
  margin-bottom: 20px; }

.rp_open_5__subtitle {
  color: #fff;
  line-height: 22px; }

.rp_open__calculation {
  width: 100%; }

.rp_open__calculation_title {
  font-size: 29px;
  font-weight: bold;
  color: $brand-primary;
  text-align: center;
  margin-bottom: 60px; }

.rp_open__calculation_bottom_title {
  font-size: 21px;
  color: $brand-primary;
  font-weight: 300;
  letter-spacing: 1px; }

.rp_open__calculation_blocks {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }

.rp_open__calculation_block {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 100px; }

.rp_open__calculation_block_1 {
  @media(min-width: $screen-md-min) {
    margin-left: -54px; } }

.rp_open__calculation_block_2 {}
.rp_open__calculation_block_3 {
  @media(min-width: $screen-md-min) {
    margin-left: 30px; } }
.rp_open__calculation_block_4 {
  @media(min-width: $screen-md-min) {
    margin-right: -49px; } }

.rp_open__calculation_block__circle {}

.rp_open__calculation_block__content {
  padding: 28px 33px;
  border-radius: 50px;
  position: relative;
  color: #fff;
  text-align: left;
  font-size: 16px;
  font-weight: 100;
  &.right {
    padding-right: 80px;
    &::before {
      border-radius: 78px 0 0 78px; } }
  &.left {
    padding-left: 80px;
    &::before {
      border-radius: 0 78px 78px 0; } }
  & > span {
    display: block;
    clear: both;
    & + span {
      margin-top: 15px; } }
  &.red {
    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
      background: $brand-danger; } }
  &.blue {
    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
      background: $brand-primary; } }
  &.grey {
    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
      background: #9c9c9c; } }
  & > .rp_open__calculation_block__content__insetborder {
    position: absolute;
    z-index: 4;
    background: #fff;
    border-radius: 100%;
    width: 200px;
    height: 200px;
    top: 50%;
    margin-top: -100px; }
  &.right {
    & > .rp_open__calculation_block__content__insetborder {
      right: -134px; } }
  &.left {
    & > .rp_open__calculation_block__content__insetborder {
      left: -134px; } }
  & + .rp_open__calculation_block__circle {
    margin-left: -48px; } }

.rp_open__calculation_block__circle {
  width: 162px;
  height: 162px;
  min-width: 162px;
  color: #fff;
  font-weight: bold;
  font-size: 29px;
  text-transform: uppercase;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 34;
  &:before {
    content: '';
    width: 194px;
    height: 194px;
    background-size: contain;
    position: absolute;
    left: -16px;
    top: -16px;
    background-repeat: no-repeat; }
  &.red {
    &:before {
      background-image: url('/assets/img/icons/circle-red.png'); }
    background: $brand-danger; }
  &.blue {
    &:before {
      background-image: url('/assets/img/icons/circle-blue.png'); }
    background: $brand-primary; }
  &.grey {
    &:before {
      background-image: url('/assets/img/icons/circle-grey.png'); }
    background: #9c9c9c; }
  & + .rp_open__calculation_block__content {
    margin-left: -48px; }
  @media (max-width: $screen-md-min) {
    width: 120px;
    height: 120px;
    min-width: 120px;
    font-size: 21px;
    &:before {
      width: 140px;
      height: 140px;
      left: -10px;
      top: -10px; } } }

.rp_open__calculation_block__circle__dots {
  position: absolute;
  &.grey {
    & > .rp_open__calculation_block__circle__dot {
      background: #9c9c9c; } }
  &.blue {
    & > .rp_open__calculation_block__circle__dot {
      background: $brand-primary; } }
  &.red {
    & > .rp_open__calculation_block__circle__dot {
      background: $brand-danger; } }
  &.to_top {
    bottom: calc(100% + 16px);
    left: 50%;
    margin-left: -3px;
    .rp_open__calculation_block__circle__dot {
      margin-bottom: 7px; } }
  &.to_right {
    left: calc(100% + 23px);
    top: 50%;
    margin-top: -6px;
    width: inherit;
    .rp_open__calculation_block__circle__dot {
      float: left;
      margin-right: 7px; } } }

.rp_open__calculation_block__circle__dot {
  width: 4px;
  height: 4px;
  border-radius: 100%; }
