.body {
  background: #fff;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative; }

* {
  &:focus, &:hover:focus {
    outline: none!important; } }
