.navbar_categories {
  border: none;
  margin: 5px 0 20px; }

.navbar_categories__toggle {
  width: 100%;
  border-radius: 0;
  & .caret {
    background-image: url('/assets/img/icons/arrow-white.png');
    margin-left: 6px;
    transform: rotate(180deg);
    transition: all .2s ease; }
  &.collapsed {
    & .caret {
      transform: rotate(00deg);
      transition: all .2s ease; } } }
