.sidebar {
  width: 100%; }

.sidebar__menu {
  width: 100%;
  border: 1px solid #e3e3e3;
  margin-bottom: 30px; }

.sidebar__menu__block {
  padding: 18px;
  & + .sidebar__menu__block {
    border-top: 1px solid #e3e3e3; } }

.sidebar__menu__link {
  font-family: "Freeset";
  display: block;
  color: $text-color;
  font-weight: 300;
  &:first-child {
    font-family: inherit;
    margin-bottom: 14px;
    color: $link-color; }
  &.active {
    color: $link-color;
    font-weight: bold; }
  & + .sidebar__menu__link {
    margin-top: 2px; }
  &:after {
    content: attr(data-count);
    margin-left: 5px;
    color: #7c7b7c; } }

.sidebar__form {
  padding: 0 25px;
  @media (max-width: $screen-lg-min) {
    padding: 0; }
  .btn {
    width: 100%;
    margin-top: 16px; } }

.sidebar__form__notice {
  font-size: 12px;
  display: block;
  margin-top: 22px;
  a {
    text-decoration: underline;
    &:hover {
      text-decoration: none; } } }

.sidebar__banner {
  width: 100%;
  padding: 6px 25px 14px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: cover;
  position: relative;
  height: 330px;
  &::after {
    content: '';
    bottom: 0;
    left: 0;
    position: absolute;
    z-index: 0;
    width: 100%;
    height: 50%;
    @include gradient-directional(rgba(0,0,0,0), rgba(0,0,0,1), 180deg); }
  & > * {
    position: relative;
    z-index: 2; }
  & > .btn {
    width: 100%; }
  @media (max-width: $screen-lg-min) {
    padding: 6px 15px; } }

.sidebar__banner__text {
  color: #fff;
  text-transform: uppercase;
  text-align: left;
  font-size: 16px;
  margin-top: 15px; }
