.consult_form {
  display: inline-block;
  width: 100%;
  margin-top: 70px;
  @media (max-width: $screen-sm-min) {
    margin-top: 30px; } }

.consult_form__text {
  width: calc(100% - 390px);
  padding-right: 70px;
  padding-top: 60px;
  @media (max-width: $screen-sm-min) {
    width: 100%;
    padding: 0; } }


.consult_form__text__main {
  font-size: 43px;
  color: $brand-primary;
  font-weight: bold;
  line-height: 52px;
  @media (max-width: $screen-md-min) {
    line-height: 33px; } }

.consult_form__text__add {}


.consult_form__form {
  @media (max-width: $screen-sm-min) {
    width: 100%;
    clear: both;
    margin-top: 50px; }
  position: relative;
  &::before {
    content: '';
    position: absolute;
    left: -91px;
    top: -38px;
    width: 410px;
    height: 501px;
    z-index: 2;
    background: url('/assets/img/consult/bg.png');
    background-repeat: no-repeat;
    background-size: contain; }
  &.no-image {
    &::before {
      display: none; } }
  & > form {
    @media (max-width: $screen-sm-min) {
      margin: 0 auto; }
    @media (max-width: $screen-xs-min) {
      width: 100%; }
    position: relative;
    z-index: 3;
    width: 390px;
    max-width: 100%;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 0 5px rgba(0,0,0,0.3);
    padding: 30px 30px 10px;
    & .form-group {
      margin-bottom: 20px;
      width: 100%; }
    & .form-label {
      width: 93px;
      color: #7c7b7c; } } }

.consult_form__form__notice {
  display: block;
  font-size: 12px;
  text-align: center;
  a {
    text-decoration: underline;
    &:hover {
      text-decoration: none; } } }

.consult_form__form__title {
  font-weight: bold;
  font-size: 21px;
  display: block;
  text-align: center;
  margin-bottom: 30px; }
