.collection_preview {
  margin-bottom: 30px;
  &:hover {
    .collection_preview__hover {
      opacity: 1;
      transition: all .2s ease; } } }

.collection_preview__images {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start; }

.collection_preview__mainimage {
  height: 218px;
  width: 100%;
  margin-right: 9px;
  background-color: #eee;
  position: relative;
  background-position: center;
  @media(max-width: $screen-sm-min) {
    margin-right: 0; } }

.collection_preview__hover {
  position: absolute;
  opacity: 0;
  transition: all .2s ease;
  background: rgba(0,0,0,0.6);
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  color: #fff;
  padding: 12px;
  font-size: 18px; }

.collection_preview__price {
  align-self: flex-end; }

.collection_preview__morelink {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 4; }



.collection_preview__colors {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 90px;
  min-width: 90px;
  height: 218px; }


.collection_preview__color {
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #eee;
  height: calc((100% - 18px) / 3);
  min-height: calc((100% - 18px) / 3);
  cursor: pointer;
  & + .collection_preview__color {
    margin-top: 9px; } }

.collection_preview__title {
  font-size: 18px;
  font-weight: bold;
  margin-top: 6px;
  display: block; }
