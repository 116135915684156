.director {
  background: $brand-primary;
  padding: 60px 0; }

.director__photo {
  img {
    max-width: 100%; } }

.director__content {
  padding-top: 25px; }

.director__title {
  color: #fff;
  font-size: 21px;
  font-weight: bold; }

.director__subtitle {
  color: $brand-danger;
  font-size: 21px;
  font-style: italic;
  font-weight: 100; }

.director__text {
  color: #fff;
  margin-top: 55px;
  font-weight: 300; }

.director__sign {
  margin-top: 30px; }
